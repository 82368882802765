import React from "react";
import { useTranslation } from "react-i18next";
import AiHomeImg from "./ai bot gif.gif";

import "./ai-hero.css";

const AiHero = () => {
  const { t } = useTranslation();

  const handleCall = () => {
    window.location.href = `tel:+92 323 2675312`;
  };

  const sectionScrollHandler = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <section id="ai_hero_section">
        <div id="ai_hero_text_div">
          <h1 id="ai_hero_main_hdng">{t("chatbotPage.hero.heading")}</h1>
          <p id="ai_hero_text">
            {t("chatbotPage.hero.text")}
            <br />
            <b>{t("chatbotPage.hero.highlight")}</b>
          </p>

          <div id="buttons_div">
            <button
              id="ai_learn_btn"
              onClick={() => sectionScrollHandler("form_section")}
            >
              {t("chatbotPage.hero.learnBtn")}
            </button>
            <button id="ai_call_btn" onClick={handleCall}>
              {t("chatbotPage.hero.consultBtn")}
            </button>
          </div>
        </div>
        <div id="home_img_div">
          <center>
            <img id="home_img" src={AiHomeImg} alt="" />
          </center>
        </div>
      </section>
    </>
  );
};

export default AiHero;
