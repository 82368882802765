import React, { useState, useEffect } from "react";
import emaarLogo from './emaar logo.avif';
import paymentPlan from './payment plan.avif';
import planPdf from './plan-pdf.pdf';
import { FaCircleCheck, FaInstagram } from "react-icons/fa6";
import { HiMiniBuildingLibrary } from "react-icons/hi2";
import { CgGym, CgInstagram } from "react-icons/cg";
import { FaFacebook, FaLinkedin, FaSwimmingPool, FaTiktok, FaYoutube } from "react-icons/fa";
import { FaGamepad } from "react-icons/fa";
import { MdDoorSliding } from "react-icons/md";
import { FaFire } from "react-icons/fa";
import { PiSecurityCameraFill } from "react-icons/pi";
import { GiPowerLightning } from "react-icons/gi";
import { PiThermometerHotFill } from "react-icons/pi";

import {
    MDBInput,
    MDBCol,
    MDBRow,
    MDBCheckbox,
    MDBBtn,
    MDBIcon
} from 'mdb-react-ui-kit';

import './park-edge.css';

const ParkEdge = () => {

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [message, setMessage] = useState("");
    const [todayDate, setTodayDate] = useState(new Date().toLocaleDateString('en-CA'));

    const sectionScrollHandler = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
            });
        };
    };

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = planPdf;
        link.download = 'Emaar ParkEdge - Payment Plan.pdf';  // Set the download filename here
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleCall = () => {
        window.open(`tel:+92 323 2675312`, '_blank');
    };

    const handleMail = () => {
        window.open(`https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=CllgCJTHVQjHDvFfpGpNsrSrHqnxfpQGzkHgPggLJfGcvlXWdMcqxwpfMvRZXgjWdDnWBVgsWsB`, '_blank');
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!email || !lastName || !firstName || !contactNumber) {
            alert('Please fill in all required fields.');
            // toast('Please fill in all required fields.', {
            // });
            return;
        };

        const payload = {
            "firstName": firstName,
            "lastName": lastName,
            "userName": "azam@landtrack.pk",
            "fingerPrint": "XBWxNl4sKfHLpl0wiIdZFJ9WMze2utIQsyAyl8RM4JtvyZYoVo3T9ihy97jybhOWZkQljg4J5tHgneJtbAvnjj9rY3RNWijjIIId",
            "userID": 1077,
            "personalMobile": contactNumber,
            "personalMobileCountryCode": "+92",
            // "rmIDs": "1077",
            "tag": "New Lead",
            "sendWelcomeLetter": false,
            "contactTypeIDs": "354",
            "leadStatus": [
                {
                    "message": message,
                    "contactusStatusID": 367,
                    "assignedTo": 1077,
                    "assignedToBy": 1077,
                    "date": todayDate
                }
            ]
        };


        try {
            const response = await fetch('https://core.fortify.biz/api/Contact/SetContact', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            const result = await response.json();
            console.log(result);
            alert('Form submitted successfully!', {
            });

            console.log(payload);
            console.log(todayDate);
            setFirstName('');
            setLastName('');
            setEmail('');
            setContactNumber('');
            setMessage("");

        } catch (error) {
            console.error('Submission error:', error);
            alert(error.message);

            console.log(payload);
        }
    };

    return (
        <>
            {/* home... */}
            <section id="park_edge_bg">
                <div id="park_edge_home">
                    <img src={emaarLogo} alt="emaar parkedge" />
                    <h1 id="park_edge_main_hdng">
                        Park Edge
                    </h1>
                    <p id="park_edge_main_txt">
                        A Premium Lifestyle In DHA Phase 8
                    </p>
                    <button
                        onClick={() => sectionScrollHandler('park_edge_contact')}
                        className="project_btn" id="park_edge_home_btn">
                        Booking & Details
                    </button>
                </div>
            </section>


            {/* about... */}
            <section id="park_edge_about">
                <div id="park_edge_about_hdng">
                    Escape To A New Level Of Luxury At
                    <b>Emaar Park Edge</b>
                </div>
                <div id="park_edge_about_txt" >
                    Rising like a beacon within the prestigious Emaar Oceanfront Community, Park Edge offers more than just a residence – it's a gateway to an unparalleled lifestyle. Imagine waking up to stunning sunrises over the Arabian Sea, surrounded by lush greenery and tranquility. Park Edge promises perfect harmony between the elegance of modern design and the timeless beauty of nature.
                    <span>Expected Completion: <b>2028</b>
                        <br />
                        <b>10%</b> Downpayment & <b>Quarterly</b> Instalments
                    </span>
                </div>
            </section>


            {/* featutres & plan... */}
            <section id="park_edge_features">
                <div id="park_edge_features_div">
                    <h2 id="park_edge_features_hdng">
                        Key Features & Payment Plan
                    </h2>

                    <div id="park_edge_features_points">
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            1-4 Bedroom Apartments, Signature Duplexes & Penthouse
                        </span>
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            Twin Tower Project
                        </span>
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            G+41 Floors
                        </span>
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            6 Levels Of Car Parking
                        </span>
                        <span>
                            <FaCircleCheck style={{ color: '#1D1E20', fontSize: '25px', marginRight: '10px' }} />
                            33% Construction Done
                        </span>
                    </div>
                </div>
                <div id="park_edge_payment_plan_div">
                    <img id="park_edge_payment_plan" src={paymentPlan} alt="payment plan" />
                    <button
                        onClick={handleDownload}
                        id="park_edge_payment_btn">
                        Download Plan
                    </button>
                </div>
            </section>



            {/* ameneties... */}
            <section id="park_edge_amenities">
                <div id="park_edge_amenities_img"></div>
                <div id="park_edge_amenities_content">
                    Dive into a world of comfort and convenience. Explore our extensive range of features and amenities, designed to cater to your every need and elevate your lifestyle.

                    <div id="park_edge_amenities_icn_div">
                        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <HiMiniBuildingLibrary style={{ fontSize: '50px' }} />
                            Gathering halls
                        </span>

                        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <CgGym style={{ fontSize: '50px' }} />
                            GYM & Fitness
                        </span>

                        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <FaSwimmingPool style={{ fontSize: '50px' }} />
                            Infinity Pools
                        </span>

                        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <FaGamepad style={{ fontSize: '50px' }} />
                            Gaming Rooms
                        </span>

                        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <MdDoorSliding style={{ fontSize: '50px' }} />
                            Built-In Wardrobes
                        </span>

                        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <FaFire style={{ fontSize: '50px' }} />
                            Fire-Safety System
                        </span>

                        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <PiSecurityCameraFill style={{ fontSize: '50px' }} />
                            CCTV Surveillance
                        </span>

                        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <GiPowerLightning style={{ fontSize: '50px' }} />
                            24/7 Power Backup
                        </span>

                        <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontSize: '14px', gap: '5px', paddingTop: '40px' }}>
                            <PiThermometerHotFill style={{ fontSize: '50px' }} />
                            Electric Geysers
                        </span>
                    </div>
                </div>
            </section>


            {/* contact... */}
            <section id="park_edge_contact">
                <div id="park_edge_contact_content">
                    <h2 id="park_edge_contact_hdng">
                        Get In Touch With us
                    </h2>
                    <p id="park_edge_contact_txt">
                        Don't miss out on this exclusive Ocean-Front project. Fill the form and get in touch for Booking & Updates
                    </p>
                    <hr />
                    <div id="park_edge_social_div_1">
                        <p onClick={handleCall}
                            style={{ margin: '0', cursor: 'pointer', textDecoration: 'underline' }}>+92 323 2675312</p>
                        <p onClick={handleMail}
                            style={{ margin: '0', cursor: 'pointer', textDecoration: 'underline' }}>info@landtrack.pk</p>
                        <span style={{ gap: '10px', fontSize: '25px', cursor: 'pointer' }}>
                            <FaFacebook onClick={() => window.location.replace('https://www.facebook.com/landtrack.pk/')}
                                style={{ paddingRight: '5px' }} />
                            <FaInstagram onClick={() => window.location.replace('https://www.instagram.com/landtrackpk/')}
                                style={{ paddingRight: '5px' }} />
                            <FaLinkedin onClick={() => window.location.replace('https://www.linkedin.com/company/landtrack-pk/mycompany/?viewAsMember=true')}
                                style={{ paddingRight: '5px' }} />
                            <FaYoutube onClick={() => window.location.replace('https://www.youtube.com/@landtrack')}
                                style={{ paddingRight: '5px' }} />
                            <FaTiktok onClick={() => window.location.replace('https://www.tiktok.com/@landtrackpk?lang=en')}
                                style={{ paddingRight: '5px' }} />
                        </span>
                    </div>
                </div>
                <div id="park_edge_contact_form">
                    <form id='form' onSubmit={handleSubmit}>
                        <MDBRow className='mb-4'>
                            <MDBCol id='field'>
                                <MDBInput
                                    value={firstName}
                                    onChange={e => setFirstName(e.target.value)} id='first_name_input'
                                    label='First Name'
                                />
                            </MDBCol>
                            <MDBCol>
                                <MDBInput
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)} id='last_name_input'
                                    label='Last name' />
                            </MDBCol>
                        </MDBRow>
                        <MDBInput
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            className='mb-4' type='email'
                            id='email_input'
                            label='Email address'
                            placeholder='123@abc.com' />

                        <MDBInput
                            value={contactNumber}
                            onChange={e => setContactNumber(e.target.value.replace(/^(\+92|0)?/, ''))}
                            className='mb-4' type='text'
                            id='phone_input'
                            label='Contact No.'
                            placeholder='321 0000000' />

                        <MDBInput value={message} onChange={e => setMessage(e.target.value)} className='mb-4' type='text' id='msg_input' label='Your Message' />

                        <MDBBtn type='submit' className='mb-4 submit_btn' block
                            id='form_btn'
                        >Submit</MDBBtn>
                    </form>

                    <div id="park_edge_social_div_2">
                        <p onClick={handleCall}
                            style={{ margin: '0', cursor: 'pointer', textDecoration: 'underline' }}>+92 323 2675312</p>
                        <p onClick={handleMail}
                            style={{ margin: '0', cursor: 'pointer', textDecoration: 'underline' }}>info@landtrack.pk</p>
                        <span style={{ gap: '10px', fontSize: '25px', cursor: 'pointer' }}>
                            <FaFacebook onClick={() => window.location.replace('https://www.facebook.com/landtrack.pk/')}
                                style={{ paddingRight: '5px' }} />
                            <FaInstagram onClick={() => window.location.replace('https://www.instagram.com/landtrackpk/')}
                                style={{ paddingRight: '5px' }} />
                            <FaLinkedin onClick={() => window.location.replace('https://www.linkedin.com/company/landtrack-pk/mycompany/?viewAsMember=true')}
                                style={{ paddingRight: '5px' }} />
                            <FaYoutube onClick={() => window.location.replace('https://www.youtube.com/@landtrack')}
                                style={{ paddingRight: '5px' }} />
                            <FaTiktok onClick={() => window.location.replace('https://www.tiktok.com/@landtrackpk?lang=en')}
                                style={{ paddingRight: '5px' }} />
                        </span>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ParkEdge;