import React from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

import DefaultNavigation from "./DefaultPageComponents/DefaultNavigation/DefaultNavigation";
import DefaultHome from "./DefaultPageSections/DefaultHome/DefaultHome";
import About from "./DefaultPageSections/About/About";
import Services from "./DefaultPageSections/OurServices/Services";
import Event from "./DefaultPageSections/OurEvent/Event";
import Why from "./DefaultPageSections/WhyUs/Why";
import Testimonials from "./DefaultPageSections/Testimonial/Testimonial";
import Clientele from "./DefaultPageSections/Clientele/Clientele";
import Blogs from "./DefaultPageSections/Blogs/Blogs";
import Awards from "./DefaultPageSections/Awards/Awards";
import Affiliate from "./DefaultPageSections/Affiliate/Affiliate";
import Form from "./DefaultPageComponents/Form/Form";
import Footer from "./DefaultPageComponents/Footer/Footer";
import WhatsAppButton from "./DefaultPageComponents/WhatsappBtn/WhatsappBtn";
import Popup from "./DefaultPageComponents/Popup/Popup";

const DefaultPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>
          Landtrack.pk - Pakistan's First PropTech facilitation Platform
        </title>
        <meta
          name="description"
          content="Discover Landtrack.pk, the leading PropTech facilitation platform in Pakistan. We offer AI-driven insights, advanced real estate marketing solutions and comprehensive real estate services to transform your business. Join our PropTech community today!"
        />
        <meta
          name="keywords"
          content="Proptech Pakistan, real estate technology, AI-driven real estate, AR/VR property tours, real estate marketing, property management, real estate data analytics, Landtrack.pk, digital real estate, AI data-driven insights, real estate business"
        />

        {/* Open Graph */}
        <meta
          property="og:title"
          content="Landtrack.pk - PropTech facilitation Platform"
        />
        <meta
          property="og:description"
          content="PropTech facilitation platform for data-driven decisions"
        />
        <meta
          property="og:image"
          content="https://landtrack.pk/static/media/building%20vector.819a305f0c42a88469fb.png"
        />
        <meta property="og:url" content="https://www.landtrack.pk" />
        <meta property="og:type" content="website" />

        {/* Structured Data */}
        <script type="application/ld+json">
          {`
          {
            "@context": "https://schema.org",
            "@type": "WebSite",
            "url": "https://www.landtrack.pk",
            "name": "Landtrack.pk - PropTech facilitation platform",
            "description": "Proptech facilitation platform for data-driven decisions"
          }
          `}
        </script>
      </Helmet>

      <DefaultNavigation
        navItems={[
          {
            label: t("defaultPage_nav.about"),
            sectionId: "about_section",
            type: "button",
          },
          {
            label: t("defaultPage_nav.services"),
            sectionId: "service_section",
            type: "button",
          },
          {
            label: t("defaultPage_nav.why"),
            sectionId: "why_section",
            type: "button",
          },
          {
            label: t("defaultPage_nav.platform"),
            path: "/projects",
            type: "link",
            external: true,
          },
        ]}
      />
      <DefaultHome />
      <About />
      <Services />
      <Event />
      <Why />
      <Testimonials />
      <Blogs />
      <Awards />
      <Clientele />
      <Affiliate />
      <Form />
      <Footer />
      <WhatsAppButton />
      {/* <Popup /> */}
    </>
  );
};

export default DefaultPage;
