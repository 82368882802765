import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitch from '../LangSwitch/LangSwitch';

import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBCollapse,
} from 'mdb-react-ui-kit';
import { FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { FaArrowUpRightFromSquare } from "react-icons/fa6";
import { FaCaretDown } from "react-icons/fa";

import logo from "./landtrack logo.png";

import "./default_navigation.css";

import { Link } from 'react-router-dom';

import ksa from './ksa.png';
import uae from './uae.png';
import trk from './trk.png';


// functionality...

const DefaultNavigation = ({navItems}) => {

    const { t } = useTranslation();

    const [showNavSecond, setShowNavSecond] = useState(false);
    const [openBasic, setOpenBasic] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [isToggeled, setIsToggeled] = useState(false);
    // const [isMobile, setIsMobile] = useState(checkResponsive());


    const checkResponsive = () => {
        if (window.innerWidth < 450) {
            return true;
        }
        else if (window.innerWidth > 450) {
            return false;
        };
    };

    const scrollHandler = () => {
        if (window.pageYOffset > 100) {
            setIsScrolled(true);
        }
        else {
            setIsScrolled(false);
        };
    };

    const scrollCheck = () => {
        if (window.pageYOffset > 10) {
            setOpenBasic(false);
        };
    };

    const sectionScrollHandler = (sectionId) => {
        const section = document.getElementById(sectionId);

        if (section) {
            window.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
            });
        };
    };

    const handleCall = () => {
        window.open(`tel:+92 323 2675312`, '_blank');
    };

    const handleMail = () => {
        window.open(`https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox?compose=CllgCJTHVQjHDvFfpGpNsrSrHqnxfpQGzkHgPggLJfGcvlXWdMcqxwpfMvRZXgjWdDnWBVgsWsB`, '_blank');
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollHandler);
        window.addEventListener('scroll', scrollCheck);

        return () => {
            window.removeEventListener('scroll', scrollHandler);
            window.removeEventListener('scroll', scrollCheck);
        };
    }, []);

    useEffect(() => {
        if (openBasic) {
            setIsToggeled(true);
        }
        else {
            setIsToggeled(false);
        };
    }, [openBasic]);


    return (
        <>
            <MDBNavbar id='upper_navbar' expand='lg'>
                <MDBContainer fluid id='upper_nav_container'>

                    <MDBNavbarNav className='d-flex flex-row' id='upper_nav'>
                        <MDBNavbarItem className='nav_item'>
                            <button
                                className='upper_nav_btn'
                                onClick={() => handleCall()} >
                                <FaPhoneAlt style={{ marginRight: "10px" }} />
                                {
                                    checkResponsive() ? "" : "+92 323 2675312"
                                }
                            </button>
                        </MDBNavbarItem>

                        <MDBNavbarItem className='nav_item'>
                            <button
                                className='upper_nav_btn'
                                onClick={() => handleMail()} >
                                <IoMdMail style={{ marginRight: "10px", fontSize: '18px' }} />
                                {
                                    checkResponsive() ? "" : "info@landtrack.pk"
                                }
                            </button>
                        </MDBNavbarItem>

                        <MDBNavbarItem className='nav_item' id='country_nav_item'>
                            <button
                                className='upper_nav_btn'
                                onClick={() => window.open('https://menaproptech.com')} >
                                <img src={ksa} alt="" style={{ marginRight: "10px", width: '20px' }} />
                                {
                                    checkResponsive() ? "" : "KSA"
                                }
                            </button>

                            <button
                                className='upper_nav_btn'
                                onClick={() => window.open('https://menaproptech.com')} >
                                <img src={uae} alt="" style={{ marginRight: "10px", width: '20px' }} />
                                {
                                    checkResponsive() ? "" : "UAE"
                                }
                            </button>

                            <button
                                className='upper_nav_btn'
                                onClick={() => window.open('https://menaproptech.com')} >
                                <img src={trk} alt="" style={{ marginRight: "10px", width: '20px' }} />
                                {
                                    checkResponsive() ? "" : "Turkey"
                                }
                            </button>
                        </MDBNavbarItem>

                        <MDBNavbarItem className='nav_item' id='lang_switch_nav_item'>
                            <LanguageSwitch />
                        </MDBNavbarItem>

                    </MDBNavbarNav>

                </MDBContainer>
            </MDBNavbar>


            {/* fixed navbar...!!! */}
            <MDBNavbar id={`fixed_upper_navbar${isScrolled ? 'show' : ''}`} expand='lg'>
                <MDBContainer fluid id='upper_nav_container'>

                    <MDBNavbarNav className='d-flex flex-row' id='upper_nav'>
                        <MDBNavbarItem className='nav_item'>
                            <button
                                className='upper_nav_btn'
                                onClick={() => handleCall()} >
                                <FaPhoneAlt style={{ marginRight: "10px" }} />
                                {
                                    checkResponsive() ? "" : "+92 323 2675312"
                                }
                            </button>
                        </MDBNavbarItem>

                        <MDBNavbarItem className='nav_item'>
                            <button
                                className='upper_nav_btn'
                                onClick={() => handleMail()} >
                                <IoMdMail style={{ marginRight: "10px", fontSize: '18px' }} />
                                {
                                    checkResponsive() ? "" : "info@landtrack.pk"
                                }
                            </button>
                        </MDBNavbarItem>

                        <MDBNavbarItem className='nav_item' id='country_nav_item'>
                            <button
                                className='upper_nav_btn'
                                onClick={() => window.open('https://menaproptech.com')} >
                                <img src={ksa} alt="" style={{ marginRight: "10px", width: '20px' }} />
                                {
                                    checkResponsive() ? "" : "KSA"
                                }
                            </button>

                            <button
                                className='upper_nav_btn'
                                onClick={() => window.open('https://menaproptech.com')} >
                                <img src={uae} alt="" style={{ marginRight: "10px", width: '20px' }} />
                                {
                                    checkResponsive() ? "" : "UAE"
                                }
                            </button>

                            <button
                                className='upper_nav_btn'
                                onClick={() => window.open('https://menaproptech.com')} >
                                <img src={trk} alt="" style={{ marginRight: "10px", width: '20px' }} />
                                {
                                    checkResponsive() ? "" : "Turkey"
                                }
                            </button>
                        </MDBNavbarItem>

                        <MDBNavbarItem className='nav_item' id='lang_switch_nav_item'>
                            <LanguageSwitch />
                        </MDBNavbarItem>

                    </MDBNavbarNav>

                </MDBContainer>
            </MDBNavbar>




            <MDBNavbar expand='lg'
                className={`navbar_${isScrolled ? 'scrolled' : ''}`}
                id={`main_navbar${isScrolled ? 'scrolled' : ''}`}>
                <MDBContainer fluid id='navbar_container'>

                    <MDBNavbarBrand id='logo_container'
                    >
                        <img
                            id='logo'
                            src={logo} alt="logo"
                            onClick={() => window.location.reload()}
                        />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setOpenBasic(!openBasic)}
                        style={{ paddingRight: '7%', color: '#083555' }}
                    >
                        {isToggeled ? <MDBIcon fas icon="times" /> : <MDBIcon className='bars_icon' icon='bars' fas />}
                    </MDBNavbarToggler>

                    <MDBCollapse navbar open={openBasic} id='collapse_container'>
                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0' id='navbar'>

                        {navItems.map((item, index) => (
                            <MDBNavbarItem id='nav_item' key={index}>
                                {item.type === 'link' ? (
                                    <Link id='link' to={item.path} target={'_blank'}>
                                        <button id='nav_btn'>
                                            {item.label} {item.external && <FaArrowUpRightFromSquare style={{ marginLeft: '3px', fontSize: '10px' }} />}
                                        </button>
                                    </Link>
                                ) : (
                                    <button id='nav_btn' onClick={() => sectionScrollHandler(item.sectionId)}>
                                        {item.label}
                                    </button>
                                )}
                            </MDBNavbarItem>
                        ))}

                            {/* <MDBNavbarItem id='nav_item'>
                                <button
                                    id='nav_btn'
                                    onClick={() => sectionScrollHandler("home_section")} >
                                    {t('defaultPage_nav.home')}
                                </button>
                            </MDBNavbarItem> */}

                            {/* <MDBNavbarItem id='nav_item'>
                                <button
                                    id='nav_btn'
                                    onClick={() => sectionScrollHandler("about_section")} >
                                    {t('defaultPage_nav.about')}
                                </button>
                            </MDBNavbarItem> */}

                            {/* <MDBNavbarItem id='nav_item'>
                                <button
                                    id='nav_btn'
                                    onClick={() => sectionScrollHandler("service_section")} >
                                    {t('defaultPage_nav.services')}
                                </button>
                            </MDBNavbarItem> */}

                            {/* <MDBNavbarItem id='nav_item'>
                                <button
                                    id='nav_btn'
                                    onClick={() => sectionScrollHandler("event_section")} >
                                    {t('defaultPage_nav.events')}
                                </button>
                            </MDBNavbarItem> */}

                            {/* <MDBNavbarItem id='nav_item'>
                                <button
                                    id='nav_btn'
                                    onClick={() => sectionScrollHandler("why_section")} >
                                    {t('defaultPage_nav.why')}
                                </button>
                            </MDBNavbarItem> */}

                            {/* <MDBNavbarItem id='nav_item'>
                                <Link id='link' to='/events' target='_blank'>
                                    <button id='nav_btn'>
                                        {t('defaultPage_nav.events')} <FaArrowUpRightFromSquare style={{ marginLeft: '3px', fontSize: '10px' }} />
                                    </button>
                                </Link>
                            </MDBNavbarItem> */}

                            {/* <MDBNavbarItem id='nav_item'>
                                <Link id='link' to='/projects' target='_blank'>
                                    <button id='nav_btn'>
                                        {t('defaultPage_nav.platform')} <FaArrowUpRightFromSquare style={{ marginLeft: '3px', fontSize: '10px' }} />
                                    </button>
                                </Link>
                            </MDBNavbarItem> */}

                            <MDBDropdown className='nav_item' id='nav_item' style={{ boxShadow: 'none', position: 'relative' }}>
                                <MDBDropdownToggle id='dropdown_btn'>
                                    {t('defaultPage_nav.events.eventsMain')}
                                    <FaCaretDown style={{ marginLeft: '3px', fontSize: '13px' }} />
                                </MDBDropdownToggle>
                                <MDBDropdownMenu id='dropdown_menu'>
                                    <MDBDropdownItem link>
                                        <Link id='link' to='/proptech-club' target='_blank'>
                                            {t('defaultPage_nav.events.club')} <FaArrowUpRightFromSquare style={{ marginLeft: '3px', fontSize: '10px' }} />
                                        </Link>
                                    </ MDBDropdownItem>
                                    <MDBDropdownItem link>
                                        <Link id='link' to='https://proptech-convention.com' target='_blank'>
                                            {t('defaultPage_nav.events.convention')} <FaArrowUpRightFromSquare style={{ marginLeft: '3px', fontSize: '10px' }} />
                                        </Link>
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>

                            <MDBDropdown className='nav_item' id='nav_item' style={{ boxShadow: 'none', position: 'relative' }}>
                                <MDBDropdownToggle id='dropdown_btn'>
                                    {t('defaultPage_nav.products.productsMain')}
                                    <FaCaretDown style={{ marginLeft: '3px', fontSize: '13px' }} />
                                </MDBDropdownToggle>
                                <MDBDropdownMenu id='dropdown_menu'>
                                    <MDBDropdownItem link>
                                        <Link id='link' to='/products/datastate.ai' target='_blank'>
                                            {t('defaultPage_nav.products.datastate')} <FaArrowUpRightFromSquare style={{ marginLeft: '3px', fontSize: '10px' }} />
                                        </Link>
                                    </ MDBDropdownItem>
                                    <MDBDropdownItem link>
                                        <Link id='link' to='/products/fortify' target='_blank'>
                                            {t('defaultPage_nav.products.fortify')} <FaArrowUpRightFromSquare style={{ marginLeft: '3px', fontSize: '10px' }} />
                                        </Link>
                                    </MDBDropdownItem>
                                </MDBDropdownMenu>
                            </MDBDropdown>

                            <MDBNavbarItem id='nav_item'>
                                <Link id='link' to='https://blog.landtrack.pk' target='_blank'>
                                    <button id='nav_btn'>
                                        {t('defaultPage_nav.blog')} <FaArrowUpRightFromSquare style={{ marginLeft: '3px', fontSize: '10px' }} />
                                    </button>
                                </Link>
                            </MDBNavbarItem>

                            <MDBNavbarItem id='nav_item'>
                                <button
                                    id='nav_btn'
                                    onClick={() => sectionScrollHandler("footer_section")} >
                                    {t('defaultPage_nav.contact')}
                                </button>
                            </MDBNavbarItem>

                        </MDBNavbarNav>
                    </MDBCollapse>

                </MDBContainer>
            </MDBNavbar>


            {/* my navbar */}


            {/* <MDBNavbar expand='lg'
                className={`navbar_${isScrolled ? 'scrolled' : ''}`}
                id='main_navbar'
            >
                <MDBContainer id='navbar_main' fluid>

                    <MDBNavbarBrand id='logo_container'
                        >

                        <img id='logo' src={logo} alt="" />

                    </MDBNavbarBrand>

                    <MDBNavbarBrand id='toggle_container'>

                        <MDBNavbarToggler
                            id='toggle_btn'
                            aria-expanded='false'
                            aria-label='Toggle navigation'
                            onClick={() => setShowNavSecond(!showNavSecond)}
                        >
                            {isToggeled ? <MDBIcon fas icon="times" /> : <MDBIcon className='bars_icon' icon='bars' fas />}
                        </MDBNavbarToggler>

                    </MDBNavbarBrand>

                    <MDBCollapse navbar show={showNavSecond} id='navbar_collapse'>
                        <MDBNavbarNav id='navbar_nav'>

                            <MDBNavbarItem className='nav_item'>
                                
                            </MDBNavbarItem>

                            

                            <MDBNavbarItem className='nav_item'>
                                
                            </MDBNavbarItem>
                        </MDBNavbarNav>
                    </MDBCollapse>

                </MDBContainer>
            </MDBNavbar > */}


            {/* gpt navbar */}


            {/* <MDBNavbar expand='lg' light bgColor='light' id="navbar_main">
                <MDBContainer fluid>
                    <MDBNavbarBrand href='#' id='logo_container'>
                        <img id='logo' src={logo} alt="Brand" style={{ height: '50%' }} />
                    </MDBNavbarBrand>

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setOpenBasic(!openBasic)}
                        id='toggle_btn'
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>

                    <MDBCollapse navbar open={openBasic} className="navbar-collapse">
                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0' id='navbar_nav'>
                            <MDBNavbarItem className='nav_item'>
                                <MDBNavbarLink active aria-current='page' href='#' className='nav_btn'>
                                    Home
                                </MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem className='nav_item'>
                                <MDBNavbarLink href='#' className='nav_btn'>Link</MDBNavbarLink>
                            </MDBNavbarItem>
                        </MDBNavbarNav>

                        <form className='d-flex input-group w-auto'>
                            <input type='search' className='form-control' placeholder='Type query' aria-label='Search' />
                            <MDBBtn color='primary'>Search</MDBBtn>
                        </form>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar> */}



        </>
    );
};

export default DefaultNavigation;
