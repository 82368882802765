import React from "react";
import { useTranslation } from "react-i18next";
import { FaCheckCircle } from "react-icons/fa";

import "./ai-about.css";
// import AiBotGif from "./ai bot gif.gif";

const AiAbout = () => {
  const sectionScrollHandler = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  const { t } = useTranslation();

  return (
    <>
      <section id="ai_about_section">
        <div id="about_txt_div">
          <h1 id="about_hdng">{t("chatbotPage.about.heading")}</h1>

          <p id="about_txt">{t("chatbotPage.about.text")}</p>
          <span className="points">
            <FaCheckCircle
              style={{ color: "#EF4723", fontSize: "17px", marginRight: "7px" }}
            />
            {t("chatbotPage.about.points.0")}
          </span>
          <span className="points">
            <FaCheckCircle
              style={{ color: "#EF4723", fontSize: "17px", marginRight: "7px" }}
            />
            {t("chatbotPage.about.points.1")}
          </span>
          <span className="points">
            <FaCheckCircle
              style={{ color: "#EF4723", fontSize: "17px", marginRight: "7px" }}
            />
            {t("chatbotPage.about.points.2")}
          </span>
          <span className="points">
            <FaCheckCircle
              style={{ color: "#EF4723", fontSize: "17px", marginRight: "7px" }}
            />
            {t("chatbotPage.about.points.3")}
          </span>
        </div>
        <button
          id="ai_package_btn"
          onClick={() => {
            sectionScrollHandler("ai_packages_section");
          }}
        >
          {t("chatbotPage.about.button")}
        </button>

        {/* <div id="ai_about_txt_div">
          <h3 id="ai_about_hdng">About AI Chatbot</h3>
          <p id="ai_about_txt">
            We present you a cutting-edge AI Chatbot designed to streamline
            customer interactions, accelerate response times, and capture every
            lead.
          </p>
          <button
            id="ai_package_btn"
            onClick={() => {
              sectionScrollHandler("ai_packages_section");
            }}
          >
            View Packages
          </button> */}
        {/* </div> */}
      </section>
    </>
  );
};

export default AiAbout;
