import React from "react";
import { useTranslation } from "react-i18next";

import DefaultNavigation from "../DefaultPage/DefaultPageComponents/DefaultNavigation/DefaultNavigation";
import AiHero from "./AiHero/AiHero";
import Form from "../DefaultPage/DefaultPageComponents/Form/Form";
import AiAbout from "./AiAbout/AiAbout";
import AiPackages from "./AiPackages/AiPackages";
import Footer from "../DefaultPage/DefaultPageComponents/Footer/Footer";

const AiBot = () => {
  const { t } = useTranslation();
  return (
    <>
      <DefaultNavigation
        navItems={[
          {
            label: t("chatbotPage.nav.about"),
            sectionId: "ai_about_section",
            type: "button",
          },
          {
            label: t("chatbotPage.nav.packages"),
            sectionId: "ai_packages_section",
            type: "button",
          },
          // {
          //   label: t("defaultPage_nav.contact"),
          //   path: "/projects",
          //   type: "link",
          //   external: true,
          // },
        ]}
      />
      <AiHero />
      <AiAbout />
      <AiPackages />
      <Form />
      <Footer />
    </>
  );
};

export default AiBot;
