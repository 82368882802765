import React from "react";
import { useTranslation } from "react-i18next";

import "./default_home.css";

import homeImage1 from "./ai vector.png";
import homeImage2 from "./building vector.png";
import homeimage3 from "./data vector.png";
import homeimage4 from "./ops vector.png";
import dataImage from "./Dashboard-bro (2).png";
import salesImage from "./Consulting-rafiki (3).png";
import eventImage from "./Conference-amico.png";
import trainingImage from "./college project-cuate.png";

import RotatingText from "../../DefaultPageComponents/RotateText/RotateText";
import RotatingImages from "../../DefaultPageComponents/rotateImg/RotateImg";

const DefaultHome = () => {
  const { t } = useTranslation();

  const handleCall = () => {
    window.location.href = `tel:+92 323 2675312`;
  };

  const sectionScrollHandler = (sectionId) => {
    const section = document.getElementById(sectionId);

    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <section id="hero_section">
        <section id="home_section">
          <div id="home_text_div">
            <h1 id="home_main_hdng">{t("defaultPage_home.main_heading")}</h1>
            <p id="home_text">
              <RotatingText
                texts={[
                  t("defaultPage_home.subtext.text1"),
                  t("defaultPage_home.subtext.text2"),
                  t("defaultPage_home.subtext.text3"),
                  t("defaultPage_home.subtext.text4"),
                ]}
                interval={3000}
              />
            </p>

            <div id="buttons_div">
              <button
                id="learn_btn"
                onClick={() => sectionScrollHandler("form_section")}
              >
                {t("defaultPage_home.learn_btn")}
              </button>
              <button id="call_btn" onClick={handleCall}>
                {t("defaultPage_home.call_btn")}
              </button>
            </div>
          </div>
          <div id="home_img_div">
            {/* <img id="home_img" src={homeImage} alt="" /> */}
            <RotatingImages
              images={[homeImage1, homeImage2, homeimage3, homeimage4]}
              interval={3000}
            />
          </div>
        </section>

        <section id="info_section">
          <div className="info_box" id="data_info_div">
            <div className="info_bg_div">
              <div className="info_icon_div">
                <img className="info_icn" src={dataImage} alt="" />
              </div>
              <h3 className="info_hdng">
                {t("defaultPage_info.data.heading")}
              </h3>
              <p className="info_txt">{t("defaultPage_info.data.text")}</p>
            </div>
          </div>

          <div className="info_box" id="cnslt_info_div">
            <div className="info_bg_div">
              <div className="info_icon_div">
                <img className="info_icn" src={salesImage} alt="" />
              </div>
              <h3 className="info_hdng">
                {t("defaultPage_info.marketing.heading")}
              </h3>
              <p className="info_txt">{t("defaultPage_info.marketing.text")}</p>
            </div>
          </div>

          <div className="info_box" id="event_info_div">
            <div className="info_bg_div">
              <div className="info_icon_div">
                <img className="info_icn" src={eventImage} alt="" />
              </div>
              <h3 className="info_hdng">
                {t("defaultPage_info.conference.heading")}
              </h3>
              <p className="info_txt">
                {t("defaultPage_info.conference.text")}
              </p>
            </div>
          </div>

          <div className="info_box" id="trnng_info_div">
            <div className="info_bg_div">
              <div className="info_icon_div">
                <img className="info_icn" src={trainingImage} alt="" />
              </div>
              <h3 className="info_hdng">
                {t("defaultPage_info.training.heading")}
              </h3>
              <p className="info_txt">{t("defaultPage_info.training.text")}</p>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default DefaultHome;
